import React from 'react'
import { Page, Questions } from '@components'

const metaData = {
  title: 'faq.seo.title',
  description: 'faq.seo.description'
}

const faq = props => {
  const { locale } = props.pageContext
  return (
    <Page locale={locale} meta={metaData}>
      <Questions />
    </Page>
  )
}

export default faq
